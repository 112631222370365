/* You can add global styles to this file, and also import other style files */


/*
* fx: flex
* ct: container
* pd: pading
* cv: center vertical
* bg: background
*/
html,
body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    background-color: #fafafa;
}

$pp-pd-g: 10px;
table {
    width: 100%!important;
}

.mat-toolbar {
    position: sticky;
    top: 0;
    z-index: 2;
}

.mat-toolbar-multiple-rows {
    min-height: 56px !important;
}

.fx-cv-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.fx-cv-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.fx-cv-sa {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.fx-cv-se {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.fx-cv-b {
    vertical-align: bottom;
}

.fx-cv-ch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.fx-ch {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    color: white;
}

.fx-cv {
    display: flex;
    align-items: center;
}

.fx-cv2 {
    display: flex;
}

.fx-cv-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fx-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.fx-spacer {
    flex: 1 1 auto;
}

.pd-general {
    padding: $pp-pd-g;
}

.pd-v {
    padding: $pp-pd-g 0px;
}

.pd-clear {
    padding: 0px 0px !important;
}

.mat-form {
    width: 100%;
}

.btn-row button {
    margin-right: 8px;
}

.group-radios mat-radio-button {
    margin-right: 10px;
}

.group-checkbox mat-checkbox {
    margin-right: 10px;
}

.ct-header-catalog {
    padding: $pp-pd-g 20px;
}

td,
th {
    width: 15%;
}

.ct-pd-table {
    td {
        padding: 0px 8px !important;
    }
    th {
        padding: 0px 8px !important;
    }
}

.ct-relative {
    position: relative;
    min-height: 200px;
    width: 100%;
}

.ct-table-relative {
    position: relative;
    max-height: 68vh;
    width: 100%;
    overflow: auto;
    min-height: 200px;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ct-alert-danger {
    background-color: #F8E0E0;
    border-left: red 5px solid;
    padding: 8px;
}

.ct-alert-info {
    background-color: #E0F2F7;
    border-left: #0174DF 5px solid;
    padding: 8px;
}

.ct-alert-success {
    background-color: #BCF5A9;
    border-left: #3ADF00 5px solid;
    padding: 8px;
}

.ct-alert-warn {
    background-color: #F3E2A9;
    border-left: #FF8000 5px solid;
    padding: 8px;
}

.slogan {
    flex-shrink: 0;
    width: 80px;
    height: 100%;
    background-image: url("/assets/images/R&B-L-07.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

mat-button-toggle-group {
    flex-wrap: wrap;
}

div.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 20px 0px;
}

.ct-avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.ct-big-avatar {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
}

.fb-tl {
    z-index: 1;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 10px;
}

.fb-tr {
    z-index: 1;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
}

.fb-br {
    z-index: 1;
    position: absolute;
    right: 0px;
    bottom: 0px;
    padding: 10px;
}

.ct-custom-browse {
    display: flex;
    border: none;
    border-radius: 10px;
}

.pd-g-h {
    padding: 0px $pp-pd-g;
}

.custom-input {
    height: 32px;
    border: none;
    width: 100%;
    padding: 0px 8px;
}

.custom-input:focus {
    border: none !important;
    /* color: $pp-primary !important;*/
    font-weight: bold;
}

.clear-pd {
    padding: 0px !important;
}

.clear-mg {
    margin: 0px !important;
}


/*styless container gallery*/

.ct-boundary {
    border: dotted #ccc 2px;
}

.ct-uploadfile {
    background-position: center;
    width: 100%;
    min-height: 50vh;
    margin: 20px auto;
    border: 2px dashed #1C8ADB;
    border-radius: 10px;
}

.ct-uploadfile:hover {
    cursor: pointer;
    background-color: #e6e9eb !important;
    opacity: 0.8;
}

.lb-DragDrop {
    color: gray;
    text-align: start;
    font-size: 20px;
    font-weight: 500;
}

.bg-excel {
    background-color: #1f7043 !important;
    color: white !important;
    font-weight: bold !important;
    border-color: #1f7043 !important;
}

.bg-pdf {
    background-color: #c7372e !important;
    color: white !important;
    font-weight: bold !important;
    border-color: #c7372e !important;
}

.clear-pd-dialog {
    .mat-dialog-container {
        padding: 0px !important;
    }
}

.clear-full-dialog{
    .mat-dialog-container {
        padding: 0px !important;
        overflow: hidden;
    }
}

.full-dialog {
    .mat-dialog-content {
        max-height: 100vh !important;
    }
}

.mat-expansion-panel-body {
    padding: 0 8px 0px !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
    width: 4px !important;
}

.mat-sidenav-content::-webkit-scrollbar {
    width: 4px !important;
}

.bg-cancel {
    background-color: #F8E0E0;
}

.ct-preview-img {
    height: 400px;
    overflow-y: auto;
}

.ct-preview-img ::-webkit-scrollbar {
    width: 4px !important;
}

.custom-title {
    font-size: 18px;
    font-weight: bold;
}

.ct-menu {
    display: flex;
    font-size: 16px;
    height: 36px;
    font-weight: 400;
    padding: 0px 8px;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 8px;
}


/**
 styless default
**/
$primary: #469fb5;
    $p-light: #7bd0e7;
    $p-dark: #007085;
    $p-light-t: #469fb580;
    $txt-on-primary: #000;
    $accent: #ffd600;
    $a-light: #ffff52;
    $a-dark: #c7a500;
    $a-light-t: #ffd60080;
    $txt-on-accent: #000;
    .txt-accent {
        color: $accent !important;
    }

    .ç {
        color: $primary !important;
    }

    .bg-drawer {
        background-color: #fafafa;
    }

    .bg-header-drawer {
        background-color: #FFF;
        color: $txt-on-accent;
    }

    .box-drawer-header{
        border-bottom-right-radius: 60px;
    }

    .bg-body-drawer {
        background-color: #fafafa;
        color: $txt-on-primary;
    }

    .bg-toolbar {
        background: none !important;
        color: $txt-on-primary !important;
    }

    .bg-primary {
        background-color: $primary !important;
        color: $txt-on-primary;
    }

    .bg-accent {
        background-color: $accent;
        color: $txt-on-accent;
    }

    .bg-p-light {
        background-color: $p-light;
        color: #000;
    }

    .bg-a-light {
        background-color: $a-light;
        color: $txt-on-accent;
    }

    .bg-p-dark {
        background-color: $p-dark;
        color: #FFF;
    }

    .bg-a-dark {
        background-color: $a-dark;
        color: #000;
    }

    .select-menu {
        border-radius: 5px;
        border: 2px solid $a-dark;
        //background-color: $p-light;
        color: #000;
        font-weight: bold;
        text-decoration: underline;
    }

    .ct-alert {
        background-color: $p-light-t;
        color: black;
        border-left: $primary 5px solid;
        padding: 8px;
    }

    .bg-primary,
    .mat-expansion-indicator {
        color: #000 !important;
    }

    .bg-primary,
    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
        color: #000 !important;
    }

    .bg-primary,
    .mat-expansion-panel {
        color: #000 !important;
    }

    .ct-drawer {
        background-color: #fafafa;
    }

    .font-txt{
        font-size: 20px;
    }

    .mat-tab-header{
        background-color: $primary !important;
        color: $txt-on-primary !important;
    }

    .txt-title {
        font-family: 'Grand Hotel', cursive;
        font-size: 28px;
        font-weight: 500;
    }

    .txt-subtitle {
        //font-family: 'Akaya Kanadaka', cursive;
        font-size:24px;
        font-weight: 600;
    }

    .ct-relative{
        border-bottom-right-radius: 20px;
    }

    .bg-main{
        background-color: white;
    }

